import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "friends-with-a-range-of-people-of-different-ages"
    }}>{`Friends with a range of people of different ages`}</h1>
    <p>{`Having relationships with people of different ages can enrich your teenager’s life in unexpected ways. Contrary to what your teenager might think, younger people aren’t always childish and older people aren’t always dull. By encouraging your teenager to spend time with people of different ages, they can gain:`}</p>
    <h3 {...{
      "id": "different-points-of-view"
    }}>{`Different points of view.`}</h3>
    <p>{`Often our opinions are shaped by our age. People of different ages can show your teenager other points of view that they may never have considered before.`}</p>
    <h3 {...{
      "id": "wisdom"
    }}>{`Wisdom.`}</h3>
    <p>{`Older people have a wealth of life experience that your teenager can benefit from. They can give some great advice.`}</p>
    <h3 {...{
      "id": "leadership-opportunities"
    }}>{`Leadership opportunities.`}</h3>
    <p>{`Mentoring younger people can give your teenager the opportunity to learn new skills.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m6/30.svg" alt="meeting people" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      